.website-information {
  font-size: 0.75rem;
}

.center-information {
  font-size: 0.688rem;
  text-align: center;
}

.center-hightlight-information {
  text-decoration: none;
  color: #ffa202;
  font-weight: bold;
}
.center-hightlight-information:hover {
  color: #ffa202;
  font-style: italic;
}
