:root {
  --nav-border-color: #cdcdcd;
  --article-background-color: white;
  --article-text-color: black;
}

[data-theme="dark"] {
  --nav-border-color: #797979;
  --article-background-color: rgb(170, 170, 170);
  --article-text-color: white;
}

.articlehr {
  color: var(--nav-border-color);
  transition: all 0.5s;
}

.full-screen {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.nav-container {
  position: sticky;
  border-bottom: 0.063rem solid var(--nav-border-color);
  transition: all 0.5s;
}

.cover-full-page {
  flex: 1;

  overflow-x: hidden;
  /* display: flex; */
  /* flex-direction: column; */
}

.ql {
  width: 100%;
  overflow: hidden;
  max-height: 5.313rem;
}

.ql-body {
  width: 100%;
  color: var(--article-text-color);
  transition: all 0.5s;
  padding: 2rem;
}

.ql img {
  max-width: 100%;
  height: auto;
  margin: 1.25rem 0rem 1.25rem 0rem;
  border-radius: 0.25rem;
}

.ql-body img {
  max-width: 100%;
  height: auto;
  border-radius: 0.25rem;
  margin: 1.25rem 0rem 1.25rem 0rem;
}

.loader-box {
  flex: 1;
  align-items: center;
  justify-items: center;
  justify-content: center;
  align-content: center;
  vertical-align: middle;
}

.paginator {
  bottom: 0rem;
}

.btn:focus {
  outline: 0 !important;
  border-color: transparent !important;
}

.btn-info {
  border-color: transparent !important;
}

:root {
  --shimmer-color: #cdcdcd;
}

[data-theme="dark"] {
  --shimmer-color: #575757;
}

.shimmer {
  background: var(--shimmer-color) !important;
}

.shimmer-title-line {
  border-radius: 0.313rem !important;
}

.no-content-box {
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  align-self: center;
}
