.lottie-height {
  height: 18.75rem;
}
.no-content-title {
  font-weight: bold;
  font-size: 0.875rem;
}

.not-found-text {
  font-weight: bolder;
  font-size: 0.625rem;
}
