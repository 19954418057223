@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

::-moz-selection {
  color: var(--text-color);
  background: var(--selection-color);
  padding: 0.625rem !important;
  border-radius: 0.625rem;
}

::selection {
  color: var(--text-color);
  background: var(--selection-color);
  padding: 0.625rem !important;
  border-radius: 0.625rem;
}

::-webkit-scrollbar {
  width: 0.375rem;
  overflow-y: auto;
}

:focus {
  border-color: transparent !important;
  box-shadow: 0 !important;
  outline: 0 none !important;
}

img {
  pointer-events: none;
}

:root {
  --border-radius: 0.525rem;
}

[data-theme="light"] {
  --border-color: #b6b6b6c7;
  --scroll-track-color: #c7c7c7c7;
  --scroll-thumb-color: #ffa6008e;
  --background-color: white;
  --navbar-background-color: white;
  --primaryColor: #ffd700;
  --accentColor: #ffa202;
  --text-color: black;
  --text-color-accent: #4a5568;
  --date-time-text: #718096;
  --shawdow-color: rgba(192, 189, 189, 0.25);
  --image-box-shawdow-color: rgba(28, 28, 28, 0.25);
  --read-more-color: #2d3748;
  --card-title-color: #2d3748;
  --popular-card-color: #f7fafc;
  --card-color: white;
  --img-brightness: brightness(1);
  --selection-color: #ffa20262;
}

[data-theme="dark"] {
  --border-color: #404040;
  --scroll-track-color: #373737c7;
  --scroll-thumb-color: #ffa600be;
  --background-color: #19181a;
  --navbar-background-color: #252527;
  --primaryColor: #ffd700;
  --accentColor: #b77400;
  --text-color: #dfdfdf;
  --text-color-accent: #637189;
  --date-time-text: #9ca9bd;
  --shawdow-color: transparent;
  --image-box-shawdow-color: rgba(79, 79, 79, 0.8);
  /* rgb(46, 46, 46); */
  --read-more-color: #626874;
  --card-title-color: #cfcfd2;
  --popular-card-color: #252527;
  --card-color: #252527;
  --img-brightness: brightness(0.8);
  --selection-color: #d3860089;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 0.313rem #FFA400; */
  border-radius: 0rem;
  border: 0.031rem var(--scroll-track-color);
  border-left-style: solid;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--scroll-thumb-color);
  border-radius: 0rem;
}

img {
  filter: var(--img-brightness);
  transition: all 0.5s;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.appbody {
  background-color: var(--background-color);
  transition: all 0.5s;
  background-image: url("assets/icons/noise.svg");
  background-repeat: repeat;
}

body {
  background-color: var(--background-color);
  transition: all 0.5s;
  font-family: "Inter" !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.animated {
  -webkit-animation: fadein 0.8s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.8s;
  /* Firefox < 16 */
  -ms-animation: fadein 0.8s;
  /* Internet Explorer */
  -o-animation: fadein 0.8s;
  /* Opera < 12.1 */
  animation: fadein 0.8s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

#logo {
  font-family: "Inter";
  font-weight: bold;
  font-size: 1.5rem;
  text-decoration: none;
  color: var(--text-color);
  transition: all 0.5s;
}

.introbox {
  padding: 2.5rem;
  margin: 0;
  max-width: 100vw !important;
}

.text-light {
  color: var(--text-color);
  transition: all 0.5s;
}

h1 {
  font-size: 3.75rem;
  color: var(--text-color);
  transition: all 0.5s;
  font-family: "Inter";
  font-weight: bold;
  line-height: 4.563rem;
}

h2 {
  font-size: 2.375rem;
  color: var(--text-color);
  transition: all 0.5s;
  font-weight: bold;
  font-family: "Inter";
  line-height: 2.25rem;
}

h3 {
  font-size: 1.875rem;
  color: var(--text-color-accent);
  transition: all 0.5s;
  font-family: "Inter";
  line-height: 2.25rem;
}

h4 {
  font-size: 1.25rem;
  color: var(--card-title-color);
  transition: all 0.5s;
  font-weight: bold;
  font-family: "Inter";
  line-height: 1.5rem;
}

h5 {
  color: var(--card-title-color);
  transition: all 0.5s;
}

.readMore {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 0.75rem !important;
  line-height: 0.938rem !important;
  color: var(--read-more-color) !important;
  transition: all 0.5s;
}

.readMore:hover {
  letter-spacing: 0.075rem;
}

.no-underline-link {
  text-decoration: none !important;
}

.date-time-text {
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.063rem;
  font-size: 0.75rem;
  line-height: 0.938rem;
  color: var(--date-time-text);
  transition: all 0.5s;
  font-size: 0.563rem;
  line-height: normal;
}

.nav-link {
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 1rem !important;
  /* line-height: 1.188rem !important; */
  margin-left: 1rem;
  margin-right: 1rem;
  color: var(--text-color);
  transition: all 0.5s;
  position: relative;
}

.nav-link::after {
  animation: scaleOut 0.6s;
  -o-animation: scaleOut 0.6s;
}

.nav-link.active {
  transition: all 0.5s;
}

.nav-link.active::after {
  content: "";
  background-color: var(--accentColor);
  left: 45%;
  bottom: -0.375rem;
  position: absolute;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 0.625rem;
  transition: all 0.5s;
  animation: scaleIn 0.6s;
  -o-animation: scaleIn 0.6s;
}

@keyframes scaleIn {
  0% {
    scale: 0;
  }

  20% {
    scale: 0;
  }

  100% {
    scale: 1;
  }
}

@keyframes scaleOut {
  0% {
    scale: 1;
  }

  100% {
    scale: 0;
  }
}

.image {
  transition: all 0.6s;
}

.cursor-pointer {
  cursor: pointer;
}

.scrollToTop {
  width: 2.625rem;
  height: 2.625rem;
  border-radius: 2.625rem;
  background-color: #662bc5 !important;
  transform: matrix(1, 0, 0, -1, 0, 0);
  color: var(--text-color);
  transition: all 0.5s;
}

footer {
  margin-top: auto;
  border-top: 0.063rem solid #e8e8e8;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  font-family: "Inter";
  font-style: normal;
  font-size: 0.938rem;
  line-height: 1.188rem;
  color: var(--text-color);
  background-color: var(--navbar-background-color) !important;
  transition: all 0.5s;
}

a.disabled {
  pointer-events: none;
}

.toolbarStyle {
  font-family: "Inter";
}

.wrapperStyle {
  font-family: "Inter";
}

.editorStyle {
  font-family: "Inter";
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  min-height: 31.25rem;
  cursor: text;
}

.editorBtn {
  border: none !important;
  min-width: 2.5rem !important;
  padding: 0rem !important;
  color: #000 !important;
}

.editorBtn:hover {
  border: none !important;
  min-width: 2.5rem !important;
  padding: 0rem !important;
  color: var(--text-color) !important;
  transition: all 0.5s;
}

.editorBtn > a {
  color: var(--text-color) !important;
  transition: all 0.5s;
  text-decoration: none;
  min-width: 2.5rem !important;
  border: none !important;
}

.dropdownStyle {
  top: 0.625rem;
  color: var(--text-color);
  font-family: "Inter";
  border: var(--text-color) 0.125rem;
  transition: all 0.5s;
  border-radius: 0.125rem !important;
  text-decoration: none !important;
  min-width: 3.125rem !important;
  border-color: #aeaeae;
  scrollbar-width: thin !important;
}

.dropdownStyle > a:hover {
  background-color: var(--accentColor) !important;
  transition: all 0.5s;
}

.rdw-fontsize-option:hover {
  background-color: var(--accentColor) !important;
  color: var(--background-color) !important;
  transition: all 0.5s;
}

rdw-dropdownoption-active {
  background: var(--accentColor) !important;
  color: var(--background-color) !important;
  transition: all 0.5s;
}

.dropdownStyle:hover {
  color: #000;
  font-family: "Inter";
  border-radius: 0.125rem;
  box-shadow: none !important;
  text-decoration: none !important;
  background-color: #fff;
  border-color: #aeaeae;
}

.image-add-btn {
  height: 18.75rem !important;
  width: 100% !important;
  border: 0.125rem dotted #282c34;
  border-radius: 0.313rem;
  text-align: center;
  align-items: center;
  justify-items: center;
  padding: auto;
  cursor: pointer;
  font-size: 16;
  font-weight: bold;
}

.image-add-btn:hover {
  border: 0.375rem dotted red;
}

.socialLogin > path {
  fill: var(--text-color) !important;
  transition: all 0.5s;
}

.socialLogin > path:hover {
  fill: var(--accentColor) !important;
  transition: all 0.5s;
}

.page-link.active-pagination-box {
  background-color: var(--accentColor) !important;
  transition: all 0.5s;
  color: #ffffff !important;
}

.editor {
  box-sizing: border-box;
  border: 0.063rem solid #ddd;
  cursor: text;
  padding: 1rem;
  border-radius: 0.125rem;
  margin-bottom: 2em;
  box-shadow: inset 0rem 0.063rem 0.5rem -0.188rem #ababab;
  background: #fefefe;
}

.editor :global(.public-DraftEditor-content) {
  min-height: 8.75rem;
}

p {
  line-height: normal;
  margin-bottom: 0rem !important;
}

.not-featured {
  filter: gray;
  /* IE6-9 */
  -webkit-filter: grayscale(1);
  /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1);
  /* Microsoft Edge and Firefox 35+ */
}

.not-featured:hover {
  -webkit-filter: grayscale(0);
  filter: none;
}

figure {
  display: block;
  margin-block-start: 0rem !important;
  margin-block-end: 0rem !important;
  margin-inline-start: 0rem !important;
  margin-inline-end: 0rem !important;
}

.public-DraftStyleDefault-block {
  margin: 0rem !important;
}

nav {
  background-color: var(--navbar-background-color);
  transition: all 0.5s;
}

.navbar-toggler:focus {
  box-shadow: var(--primaryColor) 0 0 0 0.25rem !important;
  transition: all 0.5s;
}

.readMore:hover {
  color: var(--accentColor) !important;
  transition: all 0.5s;
}

.card {
  color: var(--text-color-accent) !important;
  transition: all 0.5s;
  background-color: var(--card-color) !important;
}

.display-image {
  max-height: 21.875rem;
  aspect-ratio: 4/2.2;
  object-fit: cover;
  border-radius: 1.25rem;
  box-shadow: 0rem 0rem 0.625rem var(--image-box-shawdow-color);
}

.popular-card img {
  border-radius: 0.25rem;
}

.card img {
  border-radius: 0.25rem;
}

.pagination {
  background-color: transparent !important;
}

.page-link {
  background-color: var(--card-color) !important;
  color: var(--accentColor) !important;
  transition: all 0.5s;
  border-radius: 6.25rem !important;
  font-weight: 600;
}

.page-item {
  background-color: transparent;
  width: 2.5rem;
  border-color: 0.125rem solid var(--shawdow-color) !important;
  text-align: center;
  border-radius: 6.25rem !important;
  margin-left: 0.313rem;
  margin-right: 0.313rem;
}

.nocontent {
  color: var(--text-color);
  transition: all 0.5s;
}

/* 
.cover-image:hover {
  scale: 1.3;
} */

.backdrop-cover-image {
  max-height: 37.5rem;
  width: 100%;
  background-color: #252527;
  aspect-ratio: 26 / 9;
  object-fit: cover;
  filter: blur(0.25rem) saturate(0.6);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.cover-image-full {
  position: absolute;
  top: 0;
  left: 0;
  max-height: 37.5rem;
  width: 100%;
  aspect-ratio: 26 / 9;
  object-fit: contain;
  cursor: auto;
}

.popular-backdrop-cover-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  filter: blur(0.25rem) saturate(0.6);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.popular-cover-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: 0.25rem;
}

.share-text {
  font-size: 0.625rem;
  color: var(--text-color);
  cursor: pointer;
}

.social-box {
  background-color: #fff;
  border-radius: 0.375rem;
  padding-top: 1.875rem;
}

.search-box {
  background-color: var(--background-color) !important;
}

.category-info {
  background-color: var(--accentColor) !important;
  color: #fff !important;
  font-weight: 500 !important;
}

.category-box {
  background-color: var(--background-color) !important;
  color: var(--text-color) !important;
}

.category-box > select::after {
  background-color: var(--background-color) !important;
  color: var(--text-color) !important;
  transition: all 0.5s;
}

.category-box > select option {
  color: var(--text-color);
  transition: all 0.5s;
}

.page-active {
  background-color: var(--accentColor) !important;
  color: #fff !important;
  transition: all 2s;
}

.page-link:focus {
  box-shadow: none !important;
  border: 0.063rem solid #dee2e6 !important;
}

input:focus {
  box-shadow: none !important;
  border: 0.063rem solid #dee2e6 !important;
}

button:focus {
  box-shadow: none !important;
  border: 0.063rem solid #198754 !important;
}

button:active {
  box-shadow: none !important;
  border: 0.063rem solid #198754 !important;
}

select:focus {
  cursor: pointer;
  box-shadow: none !important;
  border: 0.063rem solid #dee2e6 !important;
}

#reading-time {
  font-size: 0.625rem;
  font-weight: 400;
  text-align: center;
  justify-content: center;

  color: var(--date-time-text);
}

.info-pill {
  background-color: var(--accentColor);
  /* background-color: #355E3B; */
  font-size: 0.625rem;
  padding: 0.125rem 0.375rem;
  border-radius: 0.25rem;
  display: inline-block;
  font-weight: 600;
  letter-spacing: 0.075rem;
  color: white;
}

#recent-title {
  padding-left: 0.375rem;
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--text-color);
  transition: all 0.5s;
}

.image-container {
  margin-top: 0.6em;
  position: relative;
  overflow: hidden;
  max-height: 37.5rem;
  width: 100%;
  aspect-ratio: 26 / 9;
  object-fit: cover;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: 0.2rem solid var(--border-color);
  padding: 0;
  border-radius: var(--border-radius);
}
