.featured-card {
  box-shadow: 0rem 0.25rem 0.25rem var(--shawdow-color);
  transition: all 0.5s;
  border-radius: var(--border-radius);
  border: 0.063rem solid var(--border-color);
  box-sizing: border-box;
}

.featured-image-box {
  border-radius: var(--border-radius);
  height: 16.25rem;
  position: relative;
  background-color: #e2e2e2;
  border: 0.063rem solid var(--border-color);
  overflow: hidden;
}

.featured-card-backdrop-cover-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  filter: blur(0.25rem) saturate(0.6);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  scale: 1.3;
}

.featured-card-cover-image {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: contain;
  height: 100%;
  width: 100%;
  transition: all 0.5s;
  cursor: zoom-in;
}

.featured-card-cover-image:hover {
  scale: 1.1 !important;
}

.featured-title {
  font-size: 1.25rem;
  max-lines: 1;
  text-overflow: ellipsis !important;
  overflow: hidden;
  color: var(--text-color);
  font-weight: 700;
  white-space: nowrap;
}

.body-view {
  flex: 1;
  padding-top: 0.5rem;
  overflow: hidden;
  font-size: 0.875rem;
  color: var(--text-color);
  mask-image: linear-gradient(180deg, #000 80%, transparent);
  -webkit-mask-image: linear-gradient(180deg, #000 85%, transparent);
}
