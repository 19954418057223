@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: local('Roboto'), url('./assets/fonts/Roboto/Roboto-Thin.ttf') format('truetype');
}

/* @font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 200;
  src: local('Roboto'), url('./assets/fonts/Roboto/Roboto-ExtraLight.ttf') format('truetype');
} */

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto'), url('./assets/fonts/Roboto/Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto'), url('./assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto'), url('./assets/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
}

/* @font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  src: local('Roboto'), url('./assets/fonts/Roboto/Roboto-SemiBold.ttf') format('truetype');
} */

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto'), url('./assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
}

/* @font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 800;
  src: local('Roboto'), url('./assets/fonts/Roboto/Roboto-ExtraBold.ttf') format('truetype');
} */

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: local('Roboto'), url('./assets/fonts/Roboto/Roboto-Black.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Roboto';
}