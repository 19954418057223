.greeting-text {
  font-size: 3.75rem;
  font-weight: bold;
}

.cover-image-box {
  width: 100%;
  max-height: 21.875rem;
  text-align: center;
  aspect-ratio: 4 / 2;
}
