.article-card {
  box-shadow: 0rem 0.25rem 0.25rem var(--shawdow-color);
  transition: all 0.5s;
  border-radius: var(--border-radius);
  border: 0.063rem solid var(--border-color);
  box-sizing: border-box;
}

.article-title {
  font-size: 1.25rem;
  max-lines: 1;
  text-overflow: ellipsis !important;
  overflow: hidden;
  color: var(--text-color);
  font-weight: 700;
  white-space: nowrap;
}

.article-backdrop-image {
  height: 11.25rem;
  width: 100%;
  aspect-ratio: 300 / 180;
  border-top: 5;
  object-fit: cover;
  filter: blur(0.25rem) saturate(0.6);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.cover-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 11.25rem;
  width: 100%;
  aspect-ratio: 300 / 180;
  border-top: 5;
  object-fit: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.article-image-box {
  border-radius: 0.325rem;
  height: 11.25rem;
  position: relative;
  background-color: #e2e2e2;
  overflow: hidden;
}

.article-card-cover-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 11.25rem;
  width: 100%;
  aspect-ratio: 300 / 180;
  border-top: 5;
  object-fit: contain;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.5s;
  cursor: zoom-in;
}

.article-card-cover-image:hover {
  scale: 1.1 !important;
}
