.popular-title {
  font-size: 1.25rem;
  color: var(--text-color);
  font-weight: 700;
  display: -webkit-box;
  max-lines: 2 !important;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis !important;
  overflow: hidden;
  white-space: nowrap;
}

.hiddenContent {
  flex: 1;
  max-lines: 3;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.063rem;
  overflow: hidden;
  color: var(--text-color);
  transition: all 0.5s;
  mask-image: linear-gradient(180deg, #000 80%, transparent);
  -webkit-mask-image: linear-gradient(180deg, #000 85%, transparent);
}

.popular-card {
  border-radius: var(--border-radius);
  transition: all 0.5s;
  border: 0.063rem solid rgba(0, 0, 0, 0.125);
  box-shadow: 0rem 0.25rem 0.25rem var(--shawdow-color);
  padding: 0;
  height: 17.5rem;
}

.popular-body {
  flex: 1;
  padding-top: 0.5rem;
  overflow: hidden;
  font-size: 0.875rem;
  mask-image: linear-gradient(180deg, #000 80%, transparent);
  -webkit-mask-image: linear-gradient(180deg, #000 85%, transparent);
}
